export default function (req, res, next) {
  const match = req.url.match(/^\/refx([a-zA-Z0-9]+)$/);

  if (match) {
    const randomCode = match[1]; 
    const cookies = req.headers.cookie || '';
    const accessToken = cookies
      .split(';')
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith('accessToken='))
      ?.split('=')[1];

    if (accessToken) {
      res.writeHead(302, { Location: `/cabinet/referral?from=${randomCode}` });
    } else {
      res.writeHead(302, { Location: `/authorization/registration?from=${randomCode}` });
    }

    res.end(); 
  } else {
    next(); 
  }
}
